import { navigate } from 'gatsby';
import { useLayoutEffect } from 'react';

import getBrowserLocale from '../utils/getBrowserLocale';

const Index = () => {
  // const siteTitle = data.site.siteMetadata?.title || `Title`;
  // const posts = data.allMarkdownRemark.nodes;
  // if (posts.length === 0) return <>no contents</>;

  useLayoutEffect(() => {
    if (getBrowserLocale() === 'ja') {
      navigate('/ja', { replace: true });
    } else {
      navigate('/en', { replace: true });
    }
  }, []);

  return null;

  // return (
  //   <Layout>
  //     <ol style={{ listStyle: `none` }}>
  //       {posts.map((post) => {
  //         const title = post.frontmatter.title || post.fields.slug;

  //         return (
  //           <li key={post.fields.slug}>
  //             <article
  //               className="post-list-item"
  //               itemScope
  //               itemType="http://schema.org/Article"
  //             >
  //               <header>
  //                 <h2>
  //                   <Link to={post.fields.slug} itemProp="url">
  //                     <span itemProp="headline">{title}</span>
  //                   </Link>
  //                 </h2>
  //                 <small>{post.frontmatter.date}</small>
  //               </header>
  //               <section>
  //                 <p
  //                   dangerouslySetInnerHTML={{
  //                     __html: post.frontmatter.description || post.excerpt,
  //                   }}
  //                   itemProp="description"
  //                 />
  //               </section>
  //             </article>
  //           </li>
  //         );
  //       })}
  //     </ol>
  //   </Layout>
  // );
};
export default Index;

// /**
//  * Head export to define metadata for the page
//  *
//  * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
//  */
// export const Head = () => (
//   <Seo title="All posts" children={undefined} lang={undefined} />
// );

// export const pageQuery = graphql`
//   query {
//     site {
//       siteMetadata {
//         title
//       }
//     }
//     allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
//       nodes {
//         excerpt
//         fields {
//           slug
//         }
//         frontmatter {
//           date(formatString: "MMMM DD, YYYY")
//           title
//           description
//         }
//       }
//     }
//   }
// `;
