const getBrowserLocale = () => {
  return (
    (window.navigator.languages && window.navigator.languages[0]) ||
    window.navigator.language ||
    (window.navigator as any).userLanguage ||
    (window.navigator as any).browserLanguage
  );
};

//test
// browserLocale = 'en';

export default getBrowserLocale;
